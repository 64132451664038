<template>
  <div class="Announcement-Table">
    <el-table
      :data="tableData"
      :height="height"
      v-if="queryRoot"
      v-loading="loading"
      ref="tableRef"
      highlight-current-row
      @row-click="rowClick"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        align="center"
        :minWidth="item.width"
        :show-overflow-tooltip="item.tooltip"
      ></el-table-column>
      <el-table-column
        prop="statusS"
        label="状态"
        align="center"
        width="100"
        v-if="updateNoticeByNoticeIdAndStudentId"
      >
        <template slot-scope="scope">
          <el-button type="text" class="state_btn">{{
            scope.row.statusS
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      v-if="queryRoot"
      @size-change = "handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <no-root v-if="!queryRoot"></no-root>
    <el-drawer
      :title="selectRow.title"
      :visible.sync="selectRow.drawer"
      size="40%"
    >
      <div class="drawer-content" style="color: #aaa">
        {{ selectRow.createTime }}
      </div>
      <div class="drawer-content" v-html="selectRow.content">
        {{ selectRow.content }}
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  QueryAllNoticeByStudent,
  UpdateNoticeByNoticeIdAndStudentId,
} from "@/libs/api/student";
export default {
  name: "accnoucement-table",
  props: {
    query: Object,
    stateOption: Array,
  },
  data() {
    return {
      height: window.innerHeight - 300,
      loading: false,
      tableData: [],
      selectRow: {
        drawer: false,
        title: null,
        content: null,
      },
      columns: [
        { prop: "title", label: "标题", width: 220, tooltip: true },
        // { prop: "announcementType", label: "公告类型" },
        { prop: "createTime", label: "时间" },
      ],
      pageSize: 10,
      current: 1,
      total: 0,
    };
  },
  watch: {
    query(n) {
      this.current = 1;
      const params = {
        ...n,
        page: this.current,
        pageSize: this.pageSize,
      };
      this.queryRoot && this.init(params);
    },
    // 权限
    queryRoot(n) {
      if (n) {
        const params = {
          page: this.current,
          pageSize: this.pageSize,
        };
        this.init(params);
      }
    },
  },
  mounted() {
    const params = {
      page: this.current,
      pageSize: this.pageSize,
    };
    this.queryRoot && this.init(params);
  },
  computed: {
    // 查询
    queryRoot() {
      return this.$sysPerKey("edunotice:queryAllNoticeByStudent");
    },
    // 查询 - 修改已读状态权限
    updateNoticeByNoticeIdAndStudentId() {
      return this.$sysPerKey("edunotice:updateNoticeByNoticeIdAndStudentId");
    },
  },
  methods: {
    init(params, isLoading) {
      if (!params) {
        params = {
          page: this.current,
          pageSize: this.pageSize,
        };
      }
      // if (!isLoading) this.loading = true;
      this.openLoadingView();
      QueryAllNoticeByStudent(params)
        .then((res) => {
          // this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.stateCount();
          this.loadingView.close();
        })
        .catch(() => {
          // this.loading = false;
          this.loadingView.close();
        });
    },
    handleCurrentChange(page) {
      if (page) this.current = page;
      this.init();
    },
    handleSizeChange(val) {
      if (val) this.pageSize = val;
      this.init();
    },
    stateCount() {
      // 课程公告未读数量
      const len = this.tableData.filter((f) => f.statusS === "未读").length;
      this.$store.dispatch("set_stu_notice", len);
    },
    rowClick(row) {
      if (!this.updateNoticeByNoticeIdAndStudentId) return;
      if (row) {
        this.selectRow = {
          drawer: true,
        };
        UpdateNoticeByNoticeIdAndStudentId({ noticeId: row.id }).then((res) => {
          if (res.data) {
            this.selectRow = {
              ...res.data,
              drawer: true,
            };
            this.init(null, true);
          }
        });
      }
    },

    propFM(row, col) {
      if (col.property === "statusS") {
        return this.stateFM(this.stateOption, row.statusS);
      } else {
        return row[col.property];
      }
    },

    stateFM(options, value) {
      return options.find((f) => f.key === value).name || "";
    },
  },
};
</script>

<style lang="less">
.Announcement-Table {
  padding: 10px;
  background: #fff;
  box-shadow: @shadow;

  .select-row {
    cursor: pointer;
  }

  .drawer-content {
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .state_btn {
    span {
      color: #ff9b22;
    }
  }
}
</style>