<template>
  <div class="Student-Announcement">
    <AnnouncementSearch @annSearch="annSeatch" :stateOption="stateOption" />
    <AnnouncementTable :query="query" :stateOption="stateOption" />
  </div>
</template>

<script>
import AnnouncementSearch from "./module/Search.vue";
import AnnouncementTable from "./module/Table.vue";
export default {
  name: "student-announcement",
  components: { AnnouncementSearch, AnnouncementTable },
  data() {
    return {
      query: null,

      stateOption: [
        { name: "全部", key: "2" },
        { name: "已读", key: "1" },
        { name: "未读", key: "0" },
      ],
    };
  },

  methods: {
    annSeatch(item) {
      this.query = item;
    },
  },
};
</script>

<style scoped lang="less">
.Student-Announcement {
}
</style>