<template>
  <div class="Announcement-Search">
    <div class="stu-module-title">通知公告</div>
    <el-form :model="form" inline ref="announcementForm" class="form-btn-group">
      <template v-if="queryRoot">
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入"
            size="medium"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="form.status" clearable filterable>
            <el-option
              v-for="(item, index) in stateOption"
              :label="item.name"
              :value="item.key"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="日期范围" prop="dateScope">
          <el-date-picker
            type="daterange"
            v-model="form.dateScope"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            range-separator="至"
            clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item class="button-item">
          <el-button @click="reset('announcementForm')">重置</el-button>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "announcement-search",
  props: {
    stateOption: Array,
  },
  data() {
    return {
      form: {
        title: "",
        status: "",
        dateScope: null,
      },
    };
  },
  computed: {
    queryRoot() {
      return this.$sysPerKey("edunotice:queryAllNoticeByStudent");
    },
  },
  methods: {
    reset(formName) {
      this.$refs[formName].resetFields();
    },
    query() {
      let status = null;
      if (this.form.status !== "2"){
         status = this.form.status;
      }
      const title = this.form.title;
      const startTime = this.form.dateScope ? this.form.dateScope[0] : null;
      const endTime = this.form.dateScope ? this.form.dateScope[1] : null;
      this.$emit("annSearch", { title, status, startTime, endTime });
    },
  },
};
</script>

<style scoped lang="less">
.Announcement-Search {
  box-shadow: @shadow;
  background: #fff;

  .stu-module-title {
    padding: 20px 10px 0;
  }
}
</style>